const photos = [
  "https://res.cloudinary.com/bodyofwater/image/upload/v1620680748/Portolio/Photography/tumblr_lxuzfrcEpF1qb1z2zo1_1280_v0eqgc.jpg",
  "https://res.cloudinary.com/bodyofwater/image/upload/v1620680748/Portolio/Photography/tumblr_lvcoskOZ9u1qb1z2zo1_1280_z1uuhi.jpg",
  "https://res.cloudinary.com/bodyofwater/image/upload/v1620680742/Portolio/Photography/tumblr_lvcoq3uSHw1qb1z2zo1_1280_gtwcat.jpg",
  "https://res.cloudinary.com/bodyofwater/image/upload/v1620680739/Portolio/Photography/tumblr_mbz3guSfRZ1qb1z2zo1_1280_s6bdpl.jpg",
  "https://res.cloudinary.com/bodyofwater/image/upload/v1620680762/Portolio/Photography/tumblr_mdvpgqZV551qb1z2zo1_1280_y9nq0g.jpg",
  "https://res.cloudinary.com/bodyofwater/image/upload/v1620680763/Portolio/Photography/tumblr_mozjvwoclq1qb1z2zo1_1280_ghlg6t.jpg",
  "https://res.cloudinary.com/bodyofwater/image/upload/v1620680759/Portolio/Photography/tumblr_mozjn92jz21qb1z2zo1_1280_jrdhtl.jpg",
  "https://res.cloudinary.com/bodyofwater/image/upload/v1620680759/Portolio/Photography/tumblr_mefibpirmE1qb1z2zo1_1280_zdyhka.jpg",
  "https://res.cloudinary.com/bodyofwater/image/upload/v1620680761/Portolio/Photography/tumblr_mono4oDbx41qb1z2zo1_1280_psxz1x.jpg",
  "https://res.cloudinary.com/bodyofwater/image/upload/v1620680762/Portolio/Photography/tumblr_mozk3gEV0w1qb1z2zo1_1280_vfj6lz.jpg",
  "https://res.cloudinary.com/bodyofwater/image/upload/v1620680755/Portolio/Photography/tumblr_mozj2ch0Eg1qb1z2zo1_1280_zv2lld.jpg",
  "https://res.cloudinary.com/bodyofwater/image/upload/v1620680747/Portolio/Photography/tumblr_lvcowkHObW1qb1z2zo1_1280_ey6c1p.jpg",
  "https://res.cloudinary.com/bodyofwater/image/upload/v1620680746/Portolio/Photography/tumblr_m7s7kwSKXa1qb1z2zo1_r2_1280_kwwijv.jpg",
  "https://res.cloudinary.com/bodyofwater/image/upload/v1620680736/Portolio/Photography/tumblr_lvconjVZM91qb1z2zo1_1280_vfhcja.jpg",
  "https://res.cloudinary.com/bodyofwater/image/upload/v1620680734/Portolio/Photography/tumblr_ll9xmyc9l11qb1z2zo1_1280_nmfbak.jpg",
  "https://res.cloudinary.com/bodyofwater/image/upload/v1620680731/Portolio/Photography/tumblr_lig5mf9gP91qb1z2zo1_1280_wjfwgm.jpg",
];
export default photos;
